import React, { useState } from 'react';
import DataViz from './DataViz';
import SectionDataViz from './SectionDataViz';
import Button from '@material-ui/core/Button';

const defaultURL = 'api/final_data';
const apiToTitle = {
  'api/final_data': 'Final Results',
  'api/section_data/0': 'Section 1 Face Fears',
  'api/section_data/1': 'Section 2 Engage with Empathy',
  'api/section_data/2': 'Section 3 Use Ethics',
  'api/section_data/3': 'Section 4 Unleash the Love of Your Mission',
};

const DataVizHome = () => {
  const [apiURL, setApiURL] = useState(defaultURL);
  const title = apiToTitle[apiURL];
  const renderDataViz = () => {
    if (apiURL === defaultURL) {
      return <DataViz />;
    } else {
      return <SectionDataViz url={apiURL} />;
    }
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setApiURL(defaultURL)}
        >
          Final Results
        </Button>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setApiURL('api/section_data/0')}
      >
        Section 1 Face Fears
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setApiURL('api/section_data/1')}
      >
        Section 2 Engage with Empathy
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setApiURL('api/section_data/2')}
      >
        Section 3 Use Ethics
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setApiURL('api/section_data/3')}
      >
        Section 4 Unleash the Love of Your Mission
      </Button>
      <h1>{title}</h1>
      {renderDataViz()}
    </>
  );
};

export default DataVizHome;
