import React, { useState, useEffect } from 'react';
import BarChart from './BarChart';
import axios from 'axios/index';
import CircularProgress from '@material-ui/core/CircularProgress';

const quizData = require('../static_data/QuizData.json');

const SectionDataViz = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const section = parseInt(props.url.charAt(props.url.length - 1));
  const fetchVizData = () => {
    setLoading(true);
    axios.get(props.url).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchVizData();
  }, [props.url]);

  return (
    <>
      {loading && <CircularProgress />}
      {data !== null &&
        loading !== true &&
        data.map((value, index) => (
          <>
            <h2>{quizData.questions[section][index]}</h2>
            <BarChart
              yDomain={5}
              key={quizData.questions[section][index] + 'e'}
              title={data[index][0]['title']}
              data={data[index][0]['data']}
            />
            <BarChart
              yDomain={5}
              key={quizData.questions[section][index] + 'a'}
              title={data[index][1]['title']}
              data={data[index][1]['data']}
            />
            <BarChart
              yDomain={5}
              key={quizData.questions[section][index] + 'c'}
              title={data[index][2]['title']}
              data={data[index][2]['data']}
            />
          </>
        ))}
    </>
  );
};

export default SectionDataViz;
