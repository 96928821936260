import React, { useEffect } from 'react';
import axios from 'axios/index';
import { Button, Table } from 'reactstrap';
import { Markup } from 'interweave';
import PropTypes from 'prop-types';
import Footer from './FooterComponent';
import Header from './HeaderComponent';

import { firebaseAuth, firebaseDB } from '../firebase';
import { addDoc, collection } from '@firebase/firestore';

const resultData = require('../static_data/ResultData.json');
const levelCopies = resultData.resultCopy;
// TODO change with config
const questionsPerPage = 8;
const maxSectionPoints = questionsPerPage * 5;
const maxPoints = maxSectionPoints * 4;

const sectionTitles = [
  'Face Fears',
  'Engage with Empathy',
  'Use Ethics',
  'Unleash the Love of Your Mission',
];

const computeScore = function (scores, part) {
  const indexBase = part * questionsPerPage; // where to start indexing
  return scores
    .slice(indexBase, indexBase + questionsPerPage)
    .reduce((a, b) => a + b, 0);
};

const Result = (props) => {
  // hack hack :D

  const [user, setUser] = React.useState(null);

  useEffect(() => {
    // Check if a user is already signed in
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        console.log('not initialized');
      }
    });
  }, []);

  window.onbeforeunload = null;

  let finalScores = {};
  // if the last state was not the test, (refresh or back) grab scores from client storage
  if (!props.location.state) {
    finalScores = JSON.parse(localStorage.getItem('finalScores'));
  } else {
    // If scores are in props, make a post request
    const scores = props.location.state.scores;
    finalScores.firstScore = computeScore(scores, 0);
    finalScores.secondScore = computeScore(scores, 1);
    finalScores.thirdScore = computeScore(scores, 2);
    finalScores.fourthScore = computeScore(scores, 3);
    finalScores.total = scores.reduce((a, b) => a + b, 0);
    const userId = JSON.parse(localStorage.getItem('user_id'));
    // TODO add error handling if local storage lost user id

    const collectionRef = collection(firebaseDB, 'final-result');

    addDoc(collectionRef, {
      user: userId,
      final_score: (finalScores.total / maxPoints) * 100,
      part_one_score: (
        (finalScores.firstScore / maxSectionPoints) *
        100
      ).toFixed(2),
      part_two_score: (
        (finalScores.secondScore / maxSectionPoints) *
        100
      ).toFixed(2),
      part_three_score: (
        (finalScores.thirdScore / maxSectionPoints) *
        100
      ).toFixed(2),
      part_four_score: (
        (finalScores.fourthScore / maxSectionPoints) *
        100
      ).toFixed(2),
    })
      .then((response) => {
        localStorage.removeItem('scores');
        localStorage.setItem('finalScores', JSON.stringify(finalScores));
      })
      .catch((error) => {
        // TODO log error
        console.error(error);
        localStorage.removeItem('scores');
        localStorage.setItem('finalScores', JSON.stringify(finalScores));
      });
  }

  let message = '';
  if (finalScores.total < 40) {
    message = levelCopies[0];
  } else if (finalScores.total < 80) {
    message = levelCopies[1];
  } else if (finalScores.total < 120) {
    message = levelCopies[2];
  } else if (finalScores.total < 140) {
    message = levelCopies[3];
  } else {
    message = levelCopies[4];
  }

  const goHome = () => {
    props.history.push('/');
  };

  const scoreArr = [
    finalScores.firstScore,
    finalScores.secondScore,
    finalScores.thirdScore,
    finalScores.fourthScore,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div className="result-container container">
        <Header />
        <h3> Scores by Section:</h3>
        <Table bordered className={'result-table'}>
          <thead>
            <tr>
              <th>Part</th>
              <th>Description</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {scoreArr.map((score, idx) => (
              <tr key={idx}>
                <th scope="row">{idx + 1}</th>
                <td className={`light-bg${idx}`}>
                  <b>{sectionTitles[idx]}</b>
                </td>
                <td>
                  {((scoreArr[idx] / maxSectionPoints) * 100).toFixed(2)}%
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <h2>
          {' '}
          Your Overall Score is {finalScores.total} / {maxPoints}
        </h2>
        <div className="result-copy">
          <Markup content={message} />
        </div>
      </div>
      <Button onClick={goHome} className="btn btn-lg btn-info retake">
        Home
      </Button>
      <Footer />
    </div>
  );
};

Result.propTypes = {
  history: PropTypes.object,
};

export default Result;
