import React, { useEffect, useState, useRef } from 'react';
import { Markup } from 'interweave';
import { Button } from 'reactstrap';
import { Progress } from 'reactstrap';

import Footer from './FooterComponent';
import Header from './HeaderComponent';
import Question from './QuestionCompoent';

import { firebaseAuth, firebaseDB } from '../firebase';
import { addDoc, collection } from '@firebase/firestore';

const quizData = require('../static_data/QuizData.json');
// TODO change with config
const questionsPerPage = 8;

const calculateCurrentProgress = (scores, part) => {
  // assuming 4 parts
  let progress = part * 25;
  const start = part * questionsPerPage;
  for (let i = start; i < start + questionsPerPage; i++) {
    if (scores[i]) {
      progress += Math.floor(25 / questionsPerPage);
    }
  }
  return progress;
};
const Quiz = (props) => {
  window.onbeforeunload = function () {
    return true;
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if a user is already signed in
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        console.log('not initialized');
      }
    });
  }, []);

  const ref = useRef([]);
  const quizPart = props.location.pathname[props.location.pathname.length - 1];
  const buttonTitle = quizPart === '3' ? 'Submit' : 'Next';
  const title = quizData.titles[quizPart];
  const questions = quizData.questions[quizPart];
  let scores = [];
  if (props.location.state !== undefined) {
    if (props.location.state.scores !== undefined) {
      scores = props.location.state.scores;
    }
  } else if (localStorage.getItem('scores')) {
    scores = JSON.parse(localStorage.getItem('scores'));
  }

  const [progress, setProgress] = useState(
    calculateCurrentProgress(scores, quizPart)
  );

  const itemsRef = useRef([]);
  // Similar to componentDidMount:
  useEffect(() => {
    window.scrollTo(0, 0);
    // you can access the elements with itemsRef.current[n]
    itemsRef.current = itemsRef.current.slice(0, questionsPerPage);
  }, []);

  const handleQuestionChange = () => {
    const collectionRef = collection(firebaseDB, 'question-response');

    let pathname = '';
    if (quizPart === '3') {
      // go to result
      pathname = '/Result';
    } else {
      pathname = '/test/' + (parseInt(quizPart) + 1) + '';
    }
    // TODO error handle user id not being in localstorage
    const userId = JSON.parse(localStorage.getItem('user_id'));
    for (let i = 0; i < questions.length; i++) {
      // Make a post request per question response
      if (userId && scores[quizPart * questionsPerPage + i]) {
        addDoc(collectionRef, {
          user: userId,
          question_id: i,
          section_id: parseInt(quizPart, 10),
          score: scores[quizPart * questionsPerPage + i],
        }).catch((error) => {
          console.error(error);
        });
      }
    }
    props.history.push({
      pathname: pathname,
      state: { scores: scores },
    });
  };

  const handleOptionChange = (id, event) => {
    scores[quizPart * questionsPerPage + id] = event;
    localStorage.setItem('scores', JSON.stringify(scores));
    setProgress(calculateCurrentProgress(scores, quizPart));

    if (id !== questionsPerPage - 1) {
      window.scrollTo({
        behavior: 'smooth',
        top: itemsRef.current[id + 1].offsetTop - 200,
      });
    }
  };

  return (
    // mapping css class based on current part of test
    <div className={`bg${quizPart}`}>
      <div className={'container'}>
        <Header />
        <div className={'quiz-container'}>
          <h1>
            <Markup content={title} />
          </h1>
          <div className={'progress-container'}>
            <div className="text-center">{`${progress}%`}</div>
            <Progress animated color="success" value={progress} />
          </div>
          {questions.map((question, index) => (
            <Question
              number={quizPart * questionsPerPage + index + 1}
              questionText={question}
              id={index}
              rref={(el) => (itemsRef.current[index] = el)}
              key={question + index}
              onChange={handleOptionChange}
              currentChoiceIdx={scores[quizPart * questionsPerPage + index]}
            />
          ))}
          <div className="quiz-button-container">
            <Button
              onClick={() => handleQuestionChange()}
              className="btn btn-lg btn-info"
            >
              {buttonTitle}
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Quiz;
