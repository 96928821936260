// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBY1jSLsoSk4StQwF3ItOM8JzVLER9EVIE',
  authDomain: 'feeltest-669f1.firebaseapp.com',
  projectId: 'feeltest-669f1',
  storageBucket: 'feeltest-669f1.appspot.com',
  messagingSenderId: '798457900913',
  appId: '1:798457900913:web:7541bcd96dced5e6c890d0',
  measurementId: 'G-5LXFYRNZWK',
};
// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth();
export const firebaseDB = getFirestore();

signInAnonymously(firebaseAuth)
  .then((userCredential) => {
    // Handle anonymous sign-in success
    const user = userCredential.user;
    console.log('Signed in anonymously as:', user.uid);
  })
  .catch((error) => {
    // Handle sign-in error
    console.error('Error signing in anonymously:', error);
  });
