import React, { useState } from 'react';
import { Markup } from 'interweave';
import PropTypes from 'prop-types';

const textToScore = {
  Never: 1,
  Rarely: 2,
  Sometimes: 3,
  Often: 4,
  Always: 5,
};
const scoreToText = {
  1: 'Never',
  2: 'Rarely',
  3: 'Sometimes',
  4: 'Often',
  5: 'Always',
};

const Question = (props) => {
  const [curChoice, setCurChoice] = useState(
    scoreToText[props.currentChoiceIdx]
  );

  const handleOptionChange = (changeEvent) => {
    if (changeEvent.target.id !== curChoice) {
      props.onChange(props.id, textToScore[changeEvent.target.id]);
      setCurChoice(changeEvent.target.id);
    }
  };

  return (
    <div ref={props.rref}>
      <h4 className="question-copy">
        <Markup content={`${props.number}.) ${props.questionText}`} />
      </h4>
      {/* TODO DRY THIS */}
      <div className="decision">
        <div className="options">
          <div
            onClick={handleOptionChange}
            className={
              'option never max ' + (curChoice === 'Never' ? 'selected' : '')
            }
            id={'Never'}
          />
          <div
            onClick={handleOptionChange}
            className={
              'option never med ' + (curChoice === 'Rarely' ? 'selected' : '')
            }
            id={'Rarely'}
          />
          <div
            onClick={handleOptionChange}
            className={
              'option middle ' + (curChoice === 'Sometimes' ? 'selected' : '')
            }
            id={'Sometimes'}
          />
          <div
            onClick={handleOptionChange}
            className={
              'option always med ' + (curChoice === 'Often' ? 'selected' : '')
            }
            id="Often"
          />
          <div
            onClick={handleOptionChange}
            className={
              'option always max ' + (curChoice === 'Always' ? 'selected' : '')
            }
            id="Always"
          />
        </div>
      </div>
      <div className="decision option-copies">
        <div className="options">
          <div className="option-copy max">Never</div>
          <div className="option-copy med">Rarely</div>
          <div className="option-copy mid">Sometimes</div>
          <div className="option-copy med">Often</div>
          <div className="option-copy max">Always</div>
        </div>
      </div>
    </div>
  );
};

Question.propTypes = {
  rref: PropTypes.func,
  number: PropTypes.number,
  questionText: PropTypes.string,
  id: PropTypes.number,
  onChange: PropTypes.func,
  currentChoiceIdx: PropTypes.number,
};

export default Question;
