import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => (
  <div className={'header-container'}>
    <ul>
      <li>
        {' '}
        <NavLink className="nav-link" to="/">
          <span className="fa fa-home fa-lg" />
          Home
        </NavLink>
      </li>
      <li>
        {' '}
        <NavLink className="nav-link" to="/about">
          <span className="fa fa-info-circle fa-lg" />
          About
        </NavLink>
      </li>
      <li>
        {' '}
        <a
          className="nav-link"
          href="https://www.deirdrebreakenridge.com/contact-us/"
          target="_blank"
        >
          <span className="fa fa-envelope fa-lg" />
          Contact Us
        </a>
      </li>
    </ul>
  </div>
);

export default Header;
