import React from 'react';
import { Route, HashRouter } from 'react-router-dom';

import About from './components/AboutComponent';
import Main from './components/MainComponent';
import Quiz from './components/QuizComponent';
import Result from './components/ResultComponent';
import Demographics from './components/DemographicsComponent';
import DataVizHome from './components/DataVizHome';
import DataByQuestion from './components/DataByQuestion';

const App = () => {
  return (
    <HashRouter>
      <div className="App">
        <Route exact path="/" component={Main} />
        <Route exact path="/demographics" component={Demographics} />
        <Route exact path="/test/0" component={Quiz} />
        <Route exact path="/test/1" component={Quiz} />
        <Route exact path="/test/2" component={Quiz} />
        <Route exact path="/test/3" component={Quiz} />
        <Route exact path="/result" component={Result} />
        <Route exact path="/about" component={About} />
        <Route exact path="/admin/data" component={DataVizHome} />
        <Route exact path="/admin/question_data" component={DataByQuestion} />
      </div>
    </HashRouter>
  );
};

export default App;
