import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import axios from 'axios';
import Footer from './FooterComponent';
import Header from './HeaderComponent';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { firebaseAuth, firebaseDB } from '../firebase';
import { addDoc, collection } from '@firebase/firestore';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const demographicData = require('../static_data/DemographicData.json');
const questions = demographicData.questions;

const renderProfessions = () => {
  const result = [<option key={'empty'} aria-label="None" value="" />];
  const professions = demographicData.professions;
  for (let i = 0; i < professions.length; i++) {
    result.push(
      <option key={professions[i]} value={professions[i]}>
        {professions[i]}
      </option>
    );
  }

  return result;
};

const Demographics = (props) => {
  const collectionRef = collection(firebaseDB, 'user-data');
  const [gender, setGender] = useState();

  const [profession, setProfession] = useState();
  const [education, setEducation] = useState();
  const [age, setAge] = useState();
  const [attempted, setAttempted] = useState(false);

  const [user, setUser] = useState(null);
  const enabled =
    age != null && gender !== null && education !== null && profession !== null;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Check if a user is already signed in
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        console.log('not initialized');
      }
    });
  }, []);

  const goToTest = () => {
    if (!enabled) {
      setAttempted(true);
      return;
    }

    addDoc(collectionRef, {
      age: age,
      gender: gender,
      occupation: profession,
      education: education,
      authedUser: user?.uid ?? undefined,
    })
      .then((docRef) => {
        console.log(docRef);
        localStorage.setItem('user_id', JSON.stringify(docRef.id));
        props.history.push('/test/0');
      })
      .catch((error) => {
        // TODO log error
        console.error(error);
        props.history.push('/test/0');
      });
  };

  const renderProfessionQuestion = () => {
    return (
      <FormControl component="fieldset" className={'dropdownDemo'}>
        <InputLabel id="demo-simple-select-label">Profession</InputLabel>
        <NativeSelect
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
        >
          {renderProfessions()}
        </NativeSelect>
      </FormControl>
    );
  };

  const renderEducationQuestion = () => {
    const choices = questions[2].choices;
    const result = [];
    for (let i = 0; i < choices.length; i++) {
      result.push(
        <FormGroup key={i} check>
          <Label check className={'demographic-text'}>
            <Input
              className={'demographic-radio'}
              type="radio"
              name={'education-questions'}
              key={choices[i]}
              onChange={() => setEducation(choices[i])}
              value={education}
            />
            <p>{' ' + choices[i] + ' '}</p>
          </Label>
        </FormGroup>
      );
    }
    return result;
  };

  const renderGenderQuestion = () => {
    const choices = questions[1].choices;
    const genderMore = (
      <Input type="text" className={'demographic-number other-input'} />
    );
    const result = [];
    for (let i = 0; i < choices.length; i++) {
      result.push(
        <FormGroup key={choices[i]} check>
          <Label check className={'demographic-text'}>
            <Input
              className={'demographic-radio'}
              type="radio"
              name={'gender-questions'}
              key={choices[i]}
              onChange={() => setGender(choices[i])}
              value={choices[i]}
            />
            <p>{' ' + choices[i] + ' '}</p>
            {i === 2 && genderMore}
          </Label>
        </FormGroup>
      );
    }
    return result;
  };
  return (
    <div className={'demographic-container'}>
      <div className={'container'}>
        <Header />
        <div className="quiz-container">
          <h2>Demographic Information</h2>
          <div className={'demographic-questions'}>
            <Form className={'demographic-form'}>
              <h3>{questions[0].question}</h3>
              <FormGroup key={'ageForm'}>
                <Input
                  type="number"
                  min="0"
                  id="age"
                  name={'number-answer'}
                  className={'demographic-number'}
                  onChange={(e) => setAge(e.target.value)}
                />
              </FormGroup>
              <h3>{questions[1].question}</h3>
              {renderGenderQuestion()}
              <h3>{questions[2].question}</h3>
              {renderEducationQuestion()}
              <h3>{questions[3].question}</h3>
              {renderProfessionQuestion()}
            </Form>
          </div>
          <Button
            disabled={!enabled && attempted}
            onClick={goToTest}
            className="btn btn-lg btn-info"
          >
            Continue to the FEEL First Test!
          </Button>
          <p className={'required-info-copy'} hidden={enabled || !attempted}>
            Please enter all information to continue
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Demographics;
