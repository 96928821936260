import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import questions from '../static_data/QuizData.json';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const defaultOptions = {
  chart: {
    type: 'column',
  },
  title: {
    text: 'My stock chart', // question
  },
  xAxis: {
    categories: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
  },
  yAxis: {
    title: {
      text: 'Number of Responses',
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
      },
    },
  },
  series: [
    {
      name: 'John',
      data: [5, 3, 4, 7, 2],
    },
    {
      name: 'Jane',
      data: [2, 2, 3, 2, 1],
    },
    {
      name: 'Joe',
      data: [3, 4, 4, 2, 5],
    },
  ],

  plotOptions: {
    column: {
      // stacking: 'normal',
      dataLabels: {
        enabled: true,
      },
    },
  },
};

const DataByQuestion = () => {
  const [data, setData] = useState();
  const [section, setSection] = useState(0);
  const [question, setQuestion] = useState(0);
  const [stacked, setStacked] = useState(true);
  const [labels, setLabels] = useState(true);
  const [occEnabled, setOccEnabled] = useState(true);
  const [genderEnabled, setGenderEnabled] = useState(true);
  const [generationEnabled, setGenerationEnabled] = useState(true);
  const [visibleTotal, setVisibleTotal] = useState([1, 1, 1]);
  const [percentData, setPercentData] = useState(false);
  const [charts, setCharts] = useState([0, 0, 0]);

  const fetchVizData = () => {
    axios.get(`api/question_data/${section}/${question}`).then((response) => {
      setData(response.data[0]);
      const data = response.data[0];
      let sums = [0, 0, 0];
      for (let i = 0; i < data.length; i++) {
        const chartData = data[i].data;
        for (let k = 0; k < chartData.length; k++) {
          if (charts[i] === 0 || charts[i].series[k].visible) {
            for (let y = 0; y < chartData[k].data.length; y++) {
              sums[i] += chartData[k].data[y];
            }
          }
        }
      }
      setVisibleTotal(sums.splice(0));
    });
  };

  const reCalculateTotal = (index) => {
    const currentChart = charts[index];
    let sum = 0;
    const series = currentChart.series;
    for (let i = 0; i < series.length; i++) {
      if (series[i].visible === true) {
        for (let j = 0; j < series[i].yData.length; j++) {
          sum += series[i].yData[j];
        }
      }
    }
    visibleTotal[index] = sum;
    setVisibleTotal(visibleTotal.splice(0));
  };

  const callBackChart = (chart, index) => {
    charts[index] = chart;
    setCharts(charts);
  };

  useEffect(() => {
    fetchVizData();
  }, [question, section]);

  return (
    <div className={'container'}>
      {data !== undefined && (
        <>
          <div className={'row'} style={{ justifyContent: 'center' }}>
            <Button
              onClick={() => {
                if (question === 0) {
                  setQuestion(7);
                  setSection(section - 1);
                } else {
                  setQuestion(question - 1);
                }
              }}
              disabled={!(question !== 0 || section !== 0)}
              color="primary"
              variant="contained"
            >
              {'Previous Question'}
            </Button>
            <Button
              onClick={() => {
                if (question === 7) {
                  setQuestion(0);
                  setSection(section + 1);
                } else {
                  setQuestion(question + 1);
                }
              }}
              color="primary"
              variant="contained"
              disabled={!(question !== 7 || section !== 3)}
            >
              {'Next Question'}
            </Button>
          </div>
          <div className={'row'} style={{ justifyContent: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={stacked}
                  onChange={() => setStacked(!stacked)}
                  color="primary"
                  name="stacked bar"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Stacked Bar"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={labels}
                  onChange={() => setLabels(!labels)}
                  color="primary"
                  name="data labels"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Data Labels"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={percentData}
                  onChange={() => setPercentData(!percentData)}
                  color="primary"
                  name="percentage"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Use Percent"
            />
          </div>
          <div className={'row'} style={{ justifyContent: 'center' }}>
            {[
              {
                setter: setOccEnabled,
                checked: occEnabled,
                label: 'Occupation',
              },
              {
                setter: setGenderEnabled,
                checked: genderEnabled,
                label: 'Gender',
              },
              {
                setter: setGenerationEnabled,
                checked: generationEnabled,
                label: 'Generation',
              },
            ].map((config) => (
              <FormControlLabel
                key={config.label}
                control={
                  <Checkbox
                    color="primary"
                    checked={config.checked}
                    onChange={() => config.setter(!config.checked)}
                  />
                }
                label={config.label}
              />
            ))}
          </div>
          <div className={'row'} style={{ justifyContent: 'center' }}>
            <Select
              id="question"
              value={question + section * 8}
              onChange={(event) => {
                const numb = event.target.value;
                setSection(Math.floor(numb / 8));
                setQuestion(numb % 8);
              }}
            >
              {questions['questions'].map((questionSet, index) => {
                return questionSet.map((q, idx) => {
                  return <MenuItem value={idx + index * 8}>{q}</MenuItem>;
                });
              })}
            </Select>
          </div>
          <h2 style={{ paddingTop: '50px' }}>
            {questions['questions'][section][question]}
          </h2>
          {[0, 1, 2].map((index) => {
            if (
              (index === 0 && occEnabled) ||
              (index === 1 && genderEnabled) ||
              (index === 2 && generationEnabled)
            ) {
              return (
                <React.Fragment key={index}>
                  <h3>Total showing {visibleTotal[index]}</h3>
                  <HighchartsReact
                    highchart={Highcharts}
                    key={index}
                    options={{
                      ...defaultOptions,
                      ...{
                        series: data[index].data,
                        title: { text: data[index].title },
                        plotOptions: {
                          column: {
                            label: 'e',
                            stacking: stacked ? 'normal' : false,
                            dataLabels: {
                              enabled: labels,
                              formatter: function () {
                                if (percentData) {
                                  let sum = 0;
                                  this.series.yData.forEach((num) => {
                                    sum += num;
                                  });
                                  return (
                                    ((this.y / sum) * 100).toFixed(1) + '%'
                                  );
                                } else {
                                  return this.y;
                                }
                              },
                            },
                          },
                          series: {
                            events: {
                              hide: function () {
                                reCalculateTotal(index);
                              },
                              show: function () {
                                reCalculateTotal(index);
                              },
                            },
                          },
                        },
                        chart: {
                          type: 'column',
                        },
                        yAxis: {
                          title: {
                            text: 'Number of Responses',
                          },
                          stackLabels: {
                            enabled: true,
                            formatter: function () {
                              if (percentData) {
                                return (
                                  (
                                    (this.total / visibleTotal[index]) *
                                    100
                                  ).toFixed(2) + '%'
                                );
                              } else {
                                return this.total;
                              }
                            },
                            style: {
                              fontWeight: 'bold',
                            },
                          },
                        },
                      },
                    }}
                    callback={(e) => callBackChart(e, index)}
                  />
                </React.Fragment>
              );
            }
          })}
        </>
      )}
    </div>
  );
};
export default DataByQuestion;
