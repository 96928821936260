import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const BarChart = (props) => {
  return <div>e</div>;
  //commented out for now
  // const d3Container = useRef(null);

  // useEffect(() => {
  //   draw();
  // }, []);

  // var margin = { top: 20, right: 20, bottom: 30, left: 40 },
  //   w = 900 - margin.left - margin.right,
  //   h = 500 - margin.top - margin.bottom;

  // const draw = () => {
  //   // NOTE: the append(g) attr.. adds margin so the axis actually shows up
  //   const svg = d3
  //     .select(d3Container.current)
  //     .append('g')
  //     .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  //   const names = props.data.map((test) => test.key);

  //   const x = d3.scaleBand().range([0, w]).padding(0.1);
  //   const y = d3.scaleLinear().range([h, 0]);
  //   x.domain(names);
  //   y.domain([0, props.yDomain]);
  //   var tooltip = d3
  //     .select('body')
  //     .append('div')
  //     .attr('class', 'tooltip-viz')
  //     .style('opacity', 0);
  //   var color = d3.schemeCategory10;
  //   svg
  //     .selectAll('rect')
  //     .data(props.data)
  //     .enter()
  //     .append('rect')
  //     .attr('x', (d) => x(d.key))
  //     .attr('y', (d) => y(d.value))
  //     .attr('width', x.bandwidth())
  //     .attr('height', (d) => h - y(d.value))
  //     .attr('fill', (d, i) => color[i])
  //     .on('mouseover', function (d, i) {
  //       d3.select(this).classed('hover', true);
  //       tooltip.transition().duration(1).style('opacity', 0.9);
  //       tooltip
  //         .html(`${d.value}% <br /> N size = ${d.count}`)
  //         .style('left', d3.event.pageX + 'px')
  //         .style('top', d3.event.pageY - 28 + 'px');
  //     })
  //     .on('mouseout', function (d, i) {
  //       d3.select(this).classed('hover', false);
  //       tooltip.transition().duration(500).style('opacity', 0);
  //     });

  //   // add the x Axis
  //   svg
  //     .append('g')
  //     .attr('transform', 'translate(0,' + h + ')')
  //     .call(d3.axisBottom(x))
  //     .style('font-size', '.8em');

  //   // add the y Axis
  //   svg.append('g').call(
  //     d3.axisLeft(y).tickFormat(function (d) {
  //       return d + '%';
  //     })
  //   );
  // };

  // return (
  //   <>
  //     <h3>{props.title}</h3>
  //     <svg
  //       className="d3-component"
  //       width={w + margin.left + margin.right}
  //       height={h + 100}
  //       ref={d3Container}
  //     />
  //   </>
  // );
};
export default BarChart;
