import React from 'react';
import { Button, Jumbotron } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import Header from './HeaderComponent';
import Footer from './FooterComponent';
import feel from '../Feel_pic.png';

const Main = (props) => {
  window.onbeforeunload = null;

  const goToTest = () => {
    props.history.push('/demographics');
  };

  localStorage.removeItem('scores');

  return (
    <div className="main-container container">
      <Header />
      <Jumbotron>
        <div className="container">
          <div className="row row-header">
            <div className="col-12 col-sm-6">
              <h2>FEEL FIRST TEST</h2>

              <h3 className={'titleInfo'}>
                If you FEEL before you communicate, you'll build stronger
                relationships through all of your online channels and in-person
                too. Here are several questions you can ask yourself about
                Facing your <b>Fears</b>, communicating with
                <b> Empathy</b>, using <b>Ethics</b> and good judgment and
                discovering your
                <b> Love</b> for your mission.
              </h3>

              <h3 className={'titleInfo'}>
                Business professionals are generally taught to consider their
                audience, timing, channels, messaging and measurement. However,
                when you add FEEL to your plan, your communications will
                resonate and your relationships will grow stronger with the
                people that matter.
              </h3>
            </div>
            <div className={'col-12 col-sm-6 main-feel-pic'}>
              <img src={feel} alt="FEEL" />
            </div>
          </div>
        </div>
      </Jumbotron>
      <div>
        <Button onClick={goToTest} className="btn btn-lg btn-info">
          Start the FEEL First Test!
        </Button>
      </div>
      <Footer />
    </div>
  );
};
export default withRouter(Main);
