import React, { useState, useEffect } from 'react';
import BarChart from './BarChart';
import axios from 'axios/index';

const DataViz = () => {
  // TODO replace with actual data for call
  const [data, setData] = useState(null);

  const fetchVizData = () => {
    axios.get('api/final_data').then((response) => {
      setData(response.data);
    });
  };

  useEffect(() => {
    fetchVizData();
  }, []);

  return (
    <>
      {data !== null && (
        <>
          <BarChart
            yDomain={100}
            title={data[0]['title']}
            data={data[0]['data']}
          />
          <BarChart
            yDomain={100}
            title={data[1]['title']}
            data={data[1]['data']}
          />
          <BarChart
            yDomain={100}
            title={data[2]['title']}
            data={data[2]['data']}
          />
        </>
      )}
    </>
  );
};

export default DataViz;
